import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import GoogleMaps from "./google_maps_controller";

export const formData = {};

export default class extends Controller {
  static targets = ["showContainer"];

  cconnect() {}

  submit() {
    const submit_button = document.querySelector("#submit-button");
    const url = "/administration/company/deliveries.json";
    const params = JSON.stringify(this.proccessParams());
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: params,
    };

    submit_button.setAttribute("disabled", "true");

    Swal.fire({
      title: "Criando corrida",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        window.location.href = data.id
          ? `/administration/deliveries/${data.id}`
          : `/administration/deliveries/new?type=detailed`;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Corrida criada com sucesso",
          text: "Redirecionando...",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      })
      .catch((error) => {
        submit_button.removeAttribute("disabled");
        new Swal({
          icon: "error",
          text: "Algo inesperado aconteceu",
          willClose: () => {
            Turbolinks.visit("/administration/deliveries");
          },
        });
      });
  }

  bindCep(event) {
    const cep = event.target.value.replace(/[\_.-]/g, "");
    const origin_address_inputs = document.querySelectorAll(
      "#origin_state, #origin_city, #origin_neighborhood, #origin_street",
    );
    const destiny_address_inputs = document.querySelectorAll(
      "#destiny_state, #destiny_city, #destiny_neighborhood, #destiny_street",
    );

    const new_address_inputs = document.querySelectorAll(
      "#new_state, #new_city, #new_neighborhood, #new_street",
    );

    const url = `/administration/addresses/${cep}.json?type=cep`;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    if (cep.length !== 8) {
      new Swal({
        text: "CEP inválido, verifique se os números estão corretos",
      });

      return;
    }

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        if (event.target.id.startsWith("origin")) {
          origin_address_inputs[0].value = data.address.city.state.abbreviation;
          origin_address_inputs[1].value = data.address.city.name;
          origin_address_inputs[2].value = data.address.neighborhood;
          origin_address_inputs[3].value = data.address.street;

          origin_address_inputs.forEach((input) => {
            input.removeAttribute("disabled");
          });
        } else if (event.target.id.startsWith("destiny")) {
          destiny_address_inputs[0].value =
            data.address.city.state.abbreviation;
          destiny_address_inputs[1].value = data.address.city.name;
          destiny_address_inputs[2].value = data.address.neighborhood;
          destiny_address_inputs[3].value = data.address.street;

          destiny_address_inputs.forEach((input) => {
            input.removeAttribute("disabled");
          });
        } else {
          new_address_inputs[0].value = data.address.city.state.abbreviation;
          new_address_inputs[1].value = data.address.city.name;
          new_address_inputs[2].value = data.address.neighborhood;
          new_address_inputs[3].value = data.address.street;

          new_address_inputs.forEach((input) => {
            input.removeAttribute("disabled");
          });
        }
      })
      .catch((error) => {
        new Swal({
          text: "CEP inválido, verifique se os números estão corretos",
        });
      });
  }

  timeline(event) {
    const step_index = event.params.step;
    const steps_container = document.querySelectorAll(
      "#step-one, #step-two, #step-three, #step-four, #step-five",
    );

    steps_container.forEach((step) => {
      step.id === step_index
        ? step.classList.remove("d-none")
        : step.classList.add("d-none");
    });
  }

  validate(step_index) {
    const timeline = document.querySelectorAll(
      "#timeline-step-one, #timeline-step-two, #timeline-step-three ",
    );

    let validated = true;
    const inputs = document.querySelectorAll(
      `#${step_index} input, #${step_index} select, #${step_index} textarea`,
    );
    const services_types = document.querySelectorAll(
      `input[type="radio"].service-types`,
    );

    inputs.forEach((input) => {
      if (!input.checkValidity()) {
        const error = document.querySelector(`#${input.name}-error`);
        error && error.remove();

        const p = document.createElement("p");
        p.id = `${input.name}-error`;
        p.classList.add("text-danger", "font-weight-bold");
        input.classList.add("border-danger");
        input.parentElement.appendChild(p);
        p.innerText = "Esse campo é obrigatório!";

        validated = false;
      } else {
        const error_element = document.querySelector(`#${input.name}-error`);
        error_element && error_element.classList.add("d-none");
        input.classList.remove("border-danger");
      }
    });

    if (formData.delivery_type) {
      const error_element = document.querySelector(`#service-type-error`);
      error_element && error_element.classList.add("d-none");
      services_types.forEach((service) => {
        document
          .querySelector(`label[for="${service.id}"]`)
          .classList.remove("border", "border-danger");
      });
    } else {
      const error = document.querySelector(`#service-type-error`);
      error && error.remove();

      const p = document.createElement("p");
      p.id = `service-type-error`;
      p.classList.add("text-danger", "font-weight-bold", "mt-3", "ml-3");

      services_types.forEach((service) => {
        document
          .querySelector(`label[for="${service.id}"]`)
          .classList.add("border", "border-danger");
      });
      error.innerText = "Escolha um tipo de serviço!";

      validated = false;
    }

    if (validated && step_index === "step-one") {
      timeline[0].setAttribute(
        "data-action",
        "click->pages--deliveries#timeline",
      );
      timeline[1].setAttribute(
        "data-action",
        "click->pages--deliveries#timeline",
      );

      timeline[0].classList.remove("timeline-disabled");
      timeline[1].classList.remove("timeline-disabled");
      timeline[0].classList.add("timeline-success");
    }

    if (validated && step_index === "step-two") {
      timeline[1].classList.add("timeline-success");
      timeline[2].classList.remove("timeline-disabled");
      timeline[2].setAttribute(
        "data-action",
        "click->pages--deliveries#timeline click->pages--deliveries#resume",
      );
    }

    return validated;
  }

  steps(event) {
    const step_index = event.params.step;
    const steps_container = document.querySelectorAll(
      "#step-one, #step-two, #step-three, #step-four",
    );

    const validated = this.validate(step_index);
    if (!validated) {
      return;
    }

    steps_container.forEach((step) => {
      const current_step = step.id === step_index && step;
      const next_step = current_step && current_step.nextElementSibling;

      current_step && current_step.classList.toggle("d-none");
      next_step && next_step.classList.toggle("d-none");
    });
  }

  getContract(event) {
    const contract_id = event.target.value;
    formData.contract_id = contract_id;

    const url = `/administration/vehicle_types.json?q[contracts_id_eq]=${contract_id}`;
    const requestOptions = {
      method: "GET",
    };

    const vehicle_type_select = document.querySelector("#vehicle_type");
    Swal.fire({
      title: "Carregando lista de veículos",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    contract_id &&
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          while (vehicle_type_select.options.length > 0) {
            vehicle_type_select.removeChild(vehicle_type_select.firstChild);
          }
          const empty_option = document.createElement("option");
          empty_option.value = "";
          empty_option.textContent = "Selecione um veículo";
          vehicle_type_select.appendChild(empty_option);

          resp.forEach((vehicle) => {
            const option = document.createElement("option");
            option.value = vehicle.id;
            option.setAttribute("name", vehicle.name);
            (option.google_travel_mode = vehicle.google_travel_mode),
              (option.textContent = vehicle.name);
            vehicle_type_select.appendChild(option);
          });
          vehicle_type_select.removeAttribute("disabled");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Lista carregada",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Houve algum erro ao carregar a lista de veículos",
            showConfirmButton: false,
            timer: 1500,
          });
        });
  }

  objectData() {
    const inputs = document.querySelectorAll(
      `#name, #vehicle_type, #weight, #package_type, #route_type, #items_description `,
    );

    formData.name = inputs[0].value;
    formData.vehicle_type = inputs[1].value;
    formData.weight = inputs[2].value;
    formData.package_type = inputs[3].value;
    formData.route_type = inputs[4].value;
    formData.items_description = inputs[5].value;
  }

  serviceType(event) {
    const type = event && event.params.type;
    const name = event && event.params.name;
    const others_services = document.querySelectorAll(`.service-card`);
    const service_selected = document.querySelector(`#${type}-service`);

    service_selected && service_selected.classList.remove("bg-warning");
    service_selected && service_selected.classList.add("bg-dark");

    service_selected &&
      others_services.forEach((service_type) => {
        if (service_selected !== service_type) {
          service_type.classList.remove("bg-dark");
          service_type.classList.add("bg-warning");
        }
      });

    formData.delivery_type = type;
    formData.display_delivery_type = name;
  }

  getCityID(city_name, uf, type) {
    const url = "/administration/company/addresses/find_address_by_city?";
    const requestOptions = {
      method: "GET",
    };
    const params = `city=${city_name}&uf=${uf}`;

    if (type === "new") {
      formData.destinations.forEach((new_destiny, index) => {
        if (index > 0) {
          const new_params = `city=${new_destiny.new_city}&uf=${new_destiny.new_state}`;
          fetch(url + new_params, requestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
              index > 0 && (new_destiny.new_city_id = resp.city_id);
            })
            .catch((error) => {});
        }
      });
      return;
    }

    fetch(url + params, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        if (type === "origin") {
          formData.origin.origin_city_id = resp.city_id;
          return;
        } else {
          formData.destinations[0].destiny_city_id = resp.city_id;
          return;
        }
      })
      .catch((error) => {});
  }

  proccessParams() {
    let destiny_params = {};
    let new_destiny_params = {};
    const params = {
      route_fee: "",
      vehicle_type_id: formData.vehicle_type,
      contract_id: formData.contract_id,
      package_type: formData.package_type,
      route_type: formData.route_type,
      delivery_type: formData.delivery_type,
      items_description: formData.items_description,
      name: formData.name,
      weight: formData.weight,
      origin: {
        what_to_do: formData.origin.origin_what_to_do,
        contact_attributes: {
          name: formData.origin.origin_contact_name,
          phone: formData.origin.origin_contact_number.replace(/\D/g, ""),
        },
        address_attributes: {
          lat: formData.origin.lat,
          lng: formData.origin.lng,
          street: formData.origin.origin_street,
          number: formData.origin.origin_number,
          city_id: formData.origin.origin_city_id,
          complement: formData.origin.origin_complement,
          neighborhood: formData.origin.origin_neighborhood,
          zipcode: formData.origin.origin_cep.replace(/\D/g, ""),
          name: "",
        },
      },
      destinations: [],
      total_distance: formData.total_distance,
    };

    const destiny = formData.origin_return
      ? formData.destinations[1]
      : formData.destinations[0];

    destiny_params = {
      what_to_do: destiny.destiny_what_to_do,
      client_number: destiny.destiny_client_number,
      contact_attributes: {
        name: destiny.destiny_contact_name,
        phone: destiny.destiny_contact_number.replace(/\D/g, ""),
      },
      address_attributes: {
        lat: destiny.lat,
        lng: destiny.lng,
        street: destiny.destiny_street,
        number: destiny.destiny_number,
        city_id: destiny.destiny_city_id,
        complement: destiny.destiny_complement,
        neighborhood: destiny.destiny_neighborhood,
        zipcode: destiny.destiny_cep.replace(/\D/g, ""),
        name: "",
      },
    };

    params.destinations.push(destiny_params);

    if (formData.destinations.length > 1) {
      formData.destinations.forEach((new_destiny) => {
        if (new_destiny.new_cep) {
          new_destiny_params = {
            what_to_do: new_destiny.new_what_to_do,
            client_number: new_destiny.new_client_number,
            contact_attributes: {
              name: new_destiny.new_contact_name,
              phone: new_destiny.new_contact_number.replace(/\D/g, ""),
            },
            address_attributes: {
              lat: new_destiny.lat,
              lng: new_destiny.lng,
              street: new_destiny.new_street,
              number: new_destiny.new_number,
              city_id: new_destiny.new_city_id,
              complement: new_destiny.new_complement,
              neighborhood: new_destiny.new_neighborhood,
              zipcode: new_destiny.new_cep.replace(/\D/g, ""),
              name: "",
            },
          };
          params.destinations.push(new_destiny_params);
        }
      });
    }

    if (formData.optimize_waypoint_order) {
      const optimize_delivery_objectives = [];

      formData.optimized_intermediate_waypoint_index.forEach((waypoint) => {
        optimize_delivery_objectives.push(params.destinations[waypoint]);
      });

      params.destinations = optimize_delivery_objectives;
    }

    if (formData.origin_return) {
      const origin_return = params.origin;

      params.destinations.push(origin_return);
    }
    return params;
  }

  deliveryObjectives(event) {
    const inputs = document.querySelectorAll(
      `#step-two input, #step-two select, #step-two textarea`,
    );

    const delivery = {};
    formData.destinations = [];
    formData.origin = {};

    inputs.forEach((input) => {
      if (input.name.startsWith("origin")) {
        formData.origin[input.name] = input.value;
      }

      if (input.name.startsWith("destiny")) {
        delivery[input.name] = input.value;
      }
    });

    delivery.client_number = document.querySelector(
      "#destiny_client_number",
    ).value;

    formData.destinations.push(delivery);

    const validate = this.validate("step-two");

    if (validate) {
      this.resume();
    }
    this.getCityID(
      formData.origin.origin_city,
      formData.origin.origin_state,
      "origin",
    );

    this.getCityID(
      formData.destinations[0].destiny_city,
      formData.destinations[0].destiny_state,
      "destiny",
    );

    formData.optimize_waypoint_order = false;
  }

  originReturn() {
    const origin_address = formData.origin;
    const full_address = `${origin_address.origin_street}, ${origin_address.origin_number} - ${origin_address.origin_neighborhood}, ${origin_address.origin_city} - ${origin_address.origin_state}, ${origin_address.origin_cep}`;
    const element = document.querySelector("#origin-return-route");
    const complement = document.querySelector("#origin-return-complement");
    const remove_button = document.querySelector("#remove-origin-return");
    const plus_route = document.querySelector("#plus-route-box");
    const origin_return_box = document.querySelector("#origin-return-box");

    element.classList.add("text-secondary");
    element.classList.remove("text-nowrap");
    origin_return_box.classList.remove("cursor-pointer");
    origin_return_box.removeAttribute("data-action");

    plus_route.classList.add("d-none");
    remove_button.classList.remove("d-none");

    formData.destinations.unshift(origin_address);

    formData.origin_return = true;
    element.innerText = full_address;
    complement.innerText = origin_address.origin_complement;

    this.resume();
  }

  removeOriginReturn() {
    const remove_button = document.querySelector("#remove-origin-return");
    const element = document.querySelector("#origin-return-route");
    const complement = document.querySelector("#origin-return-complement");
    const plus_route = document.querySelector("#plus-route-box");
    const origin_return_box = document.querySelector("#origin-return-box");

    plus_route.classList.remove("d-none");
    remove_button.classList.add("d-none");
    element.classList.remove("text-secondary");
    element.classList.add("text-nowrap");

    element.innerText = "Adicionar retorno na origem";
    complement.innerText = "";

    formData.destinations.splice(0, 1);
    formData.origin_return = false;

    origin_return_box.classList.add("cursor-pointer");
    origin_return_box.setAttribute(
      "data-action",
      "click->pages--deliveries#originReturn",
    );

    this.resume();
  }

  newDestination() {
    const inputs = document.querySelectorAll(
      `#new-delivery-address-form-box input, #new-delivery-address-form-box select, #new-delivery-address-form-box textarea`,
    );

    const new_delivery_form = document.querySelector(
      "#new-delivery-address-form-box",
    );
    const map_box = document.querySelector("#map");
    const submit_button = document.querySelector("#submit-button");
    const step_three_button = document.querySelector("#step-three-button");

    inputs.forEach((input) => {
      input.value = "";
    });

    map_box.classList.toggle("d-none");
    submit_button.classList.toggle("d-none");
    new_delivery_form.classList.toggle("d-none");
    step_three_button.classList.toggle("d-none");
  }

  addRoute() {
    let index = formData.destinations.length;
    const inputs = document.querySelectorAll(
      `#new-delivery-address-form-box input, #new-delivery-address-form-box select, #new-delivery-address-form-box textarea`,
    );

    const routes_timeline = document.querySelector("#routes-timeline");
    const delivery = {};

    inputs.forEach((input) => {
      delivery[input.name] = input.value;
    });

    formData.destinations.push(delivery);

    this.getCityID("", "", "new");

    const full_delivery_address = `${delivery.new_street}, ${delivery.new_number} - ${delivery.new_neighborhood}, ${delivery.new_city} - ${delivery.new_state}, ${delivery.new_cep}`;
    const complement = delivery.new_complement;
    routes_timeline.innerHTML += `
    <div id="route-box-${index}" class="destination" >
      <i class="bg-blue fas fa-map-marked-alt"></i>
      <div class="d-flex timeline-item shadow rounded">
        <div class="timeline-body">
          <div class="col-10">
            <h6 id="new-route-${index}" class="text-secondary">${full_delivery_address}</h6>
            <p id="new-route-complement-${index}" class="text-secondary">${complement}</p>
          </div>
          <span class="float-right bg-danger rounded-circle pl-1 pr-1 cursor-pointer">
            <i class="fas fa-times" title="Excluir endereço" data-action="click->pages--deliveries#removeDestination" data-pages--deliveries-index-param="${index}"></i>
          </span>
        </div>
      </div>
    </div>`;

    this.newDestination();
    this.resume();
  }

  removeDestination(event) {
    const index = event.params.index;
    const route = document.querySelector(`#route-box-${index}`);
    route.remove();

    formData.destinations.splice(formData.origin_return ? index + 1 : index, 1);

    this.resume();
  }

  async optimizeRoute() {
    const optimize_route_button = document.querySelector(
      "#optimize-route-button",
    );
    const elements = Array.from(document.querySelectorAll(".destination"));
    const parentElement = elements[0].parentNode;

    formData.optimize_waypoint_order = !formData.optimize_waypoint_order;

    if (formData.optimize_waypoint_order) {
      optimize_route_button.classList.replace("btn-success", "btn-dark");
      optimize_route_button.innerHTML = `<i class="fa fa-route fa-lg"></i> Rota otimizada`;
    } else {
      optimize_route_button.innerHTML = `<i class="fa fa-route fa-lg"></i> Otimizar rota`;
      optimize_route_button.classList.replace("btn-dark", "btn-success");
    }

    await this.resume();

    if (formData.optimize_waypoint_order) {
      formData.optimized_intermediate_waypoint_index &&
        formData.optimized_intermediate_waypoint_index.forEach((newIndex) => {
          elements.forEach((element) => {
            if (element.id.includes(newIndex)) {
              element.remove();
              parentElement.appendChild(element);
            }
          });
        });
    } else {
      elements.sort((a, b) => {
        return (
          parseInt(a.id.replace("route-box-", "")) -
          parseInt(b.id.replace("route-box-", ""))
        );
      });
      elements.forEach((element) => {
        element.remove();
        parentElement.appendChild(element);
      });
    }
  }

  async resume() {
    const submit_button = document.querySelector("#submit-button");
    submit_button.setAttribute("disabled", true);
    const google_maps = new GoogleMaps();

    const cards = document.querySelectorAll(
      "#vehicle_type_display, #route_type_display, #delivery_type_display, #package_type_display, #origin-route, #destiny-route",
    );

    const complements = document.querySelectorAll(
      "#origin-route-complement, #destiny-route-complement",
    );

    const optimize_route_button = document.querySelector(
      "#optimize-route-button",
    );

    formData.destinations.length > 1
      ? optimize_route_button.classList.remove("d-none")
      : optimize_route_button.classList.add("d-none");

    const origin_address = formData.origin;
    const full_origin_address = `${origin_address.origin_street}, ${origin_address.origin_number} - ${origin_address.origin_neighborhood}, ${origin_address.origin_city} - ${origin_address.origin_state}, ${origin_address.origin_cep}`;

    const destiny_address = formData.origin_return
      ? formData.destinations[1]
      : formData.destinations[0];

    const full_destiny_address = `${destiny_address.destiny_street}, ${destiny_address.destiny_number} - ${destiny_address.destiny_neighborhood}, ${destiny_address.destiny_city} - ${destiny_address.destiny_state}, ${destiny_address.destiny_cep}`;

    google_maps &&
      (await google_maps.route(
        origin_address,
        formData.destinations,
        formData.origin_return,
        formData.google_travel_mode,
        formData.routing_preference,
        formData.optimize_waypoint_order,
      ));

    cards[0].innerText = formData.display_vehicle_type;
    cards[1].innerText = formData.display_route_type;
    cards[2].innerText = formData.display_delivery_type;
    cards[3].innerText = formData.display_package_type;
    cards[4].innerText = full_origin_address;
    cards[5].innerText = full_destiny_address;
    complements[0].innerText = origin_address.origin_complement;
    complements[1].innerText = destiny_address.destiny_complement;
    submit_button.removeAttribute("disabled");
  }

  translateCards(event) {
    const options = event.target.options;
    const selected_index = event.target.options.selectedIndex;
    let translate_value = "";

    for (let i = 0; i <= options.length; i++) {
      if (i === selected_index) {
        translate_value = options[i].label;
        if (event.target.id === "vehicle_type") {
          formData.google_travel_mode = options[i].google_travel_mode;
        }
      }
    }

    if (event.target.id === "vehicle_type") {
      formData.display_vehicle_type = translate_value;

      if (
        formData.google_travel_mode === "DRIVE" ||
        formData.google_travel_mode === "TWO_WHEELER"
      ) {
        formData.routing_preference = "TRAFFIC_AWARE";
      }

      return;
    }

    if (event.target.id === "route_type") {
      formData.display_route_type = translate_value;
      return;
    }

    if (event.target.id === "package_type") {
      formData.display_package_type = translate_value;
      return;
    }
  }

  addObservation(event) {
    const buttonSubmit = document.querySelector("#button-submit-observation");
    buttonSubmit.click();

    const cardInfo = document.querySelector(".form-observation button");
    cardInfo.click();
  }

  onUpdateSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (!!event.params.redirect) {
      Turbolinks.visit("");
      return;
    }

    document.querySelector(".loader-container").classList.add("d-none");
    document.querySelector("html").classList.remove("loader-overflow");
    this.showContainerTarget.innerHTML = xhr.response;
  }

  onNotification(event) {
    const [data, status, xhr] = event.detail;

    const el = document.createElement("div");
    el.innerHTML = xhr.response;
    this.showContainerTarget.appendChild(el);
  }
}
