import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = [
    "employmentTypeField",
    "franchiseIdentifierField",
    "bankAccountFields",
    "bankAccountContainer",
    "needsDocuments",
  ];

  employmentTypeFieldTargetConnected() {
    this.onRecordBackAccount();
  }

  needsDocumentsTargetConnected(element) {
    this.needsDocuments();
    this.disableRemoveVehicle();
  }

  needsDocumentsTargetDisconnected(element) {
    // Verifica se não existe documentos para ser verificado
    if (document.querySelectorAll(".vehicle-type").length === 0) return;

    this.needsDocuments();
  }

  bankAccountFieldsTargetConnected() {
    this.disableRemoveBankAccount();
  }

  bankAccountFieldsTargetDisconnected() {
    this.disableRemoveBankAccount();
  }

  // Definir somente 1 veículo ativo no sistema da levoo
  disableRemoveVehicle(element) {
    if (element && !element.target.checked) {
      element.target.checked = true;
      toastr.error("Obrigatório ter um veículo ativo", "Entregador");
      return;
    }

    // Busca pelos elementos da tela
    const elementSearch = 'input[name*="[active]"][type="checkbox"]';

    // Filtra pelos input's do tipo cjeckbox
    const vehicleNames = this.needsDocumentsTargets.filter((target) => {
      console.log(this.needsDocumentsTargets[0]);
      if (
        !!target.querySelector(elementSearch) &&
        target.querySelector(elementSearch).checked
      ) {
        return target.querySelector(elementSearch);
      }
    });

    if (vehicleNames.length === 0) {
      // Se não tiver nada retorna uma mesagem para cirar um novo
      return;
    } else if (vehicleNames.length === 1) {
      // Se tiver pelo menos um desabilite o botão de remover
      console.log("passou aqui");
      vehicleNames[0]
        .querySelector(".remove-vehicle-button")
        .setAttribute("disabled", "true");
    } else if (vehicleNames.length >= 1) {
      // Se tiver mais de verificar o que está desabilitado atraves do botão remover
      this.needsDocumentsTargets.forEach((target) => {
        if (
          target
            .querySelector(".remove-vehicle-button")
            .getAttribute("disabled")
        ) {
          target.querySelector(elementSearch).checked = false;
          target
            .querySelector(".remove-vehicle-button")
            .removeAttribute("disabled");
        } else {
          target
            .querySelector(".remove-vehicle-button")
            .setAttribute("disabled", true);
        }
      });
    } else {
      // Se tudo der errado mandar a notificação
      toastr.error("Erro no fluxo do veículo ativo", "Entregador");
    }
  }

  // Definir as conta bancárias
  disableRemoveBankAccount(element) {
    if (element && !element.target.checked) {
      element.target.checked = true;
      toastr.error("Obrigatório ter uma conta ativa", "Entregador");
      return;
    }

    // Busca pelos elementos da tela
    const elementSearch = 'input[name*="[active]"][type="checkbox"]';

    // Filtra pelos input's do tipo checkbox
    const bankContainerElements = this.bankAccountFieldsTargets.filter(
      (target) => {
        console.log(this.bankAccountFieldsTargets[0]);

        if (
          !!target.querySelector(elementSearch) &&
          target.querySelector(elementSearch).checked
        ) {
          return target.querySelector(elementSearch);
        }
      },
    );

    if (bankContainerElements.length === 0) {
      // Se não tiver nada retorna uma mesagem para cirar um novo
      return;
    } else if (bankContainerElements.length === 1) {
      bankContainerElements[0]
        .querySelector(".remove-bank-account-button")
        .setAttribute("disabled", "true");
    } else if (bankContainerElements.length >= 1) {
      // Se tiver mais de verificar o que está desabilitado atraves do botão remover
      this.bankAccountFieldsTargets.forEach((target) => {
        if (
          target
            .querySelector(".remove-bank-account-button")
            .getAttribute("disabled")
        ) {
          target.querySelector(elementSearch).checked = false;
          target
            .querySelector(".remove-bank-account-button")
            .removeAttribute("disabled");
        } else {
          target
            .querySelector(".remove-bank-account-button")
            .setAttribute("disabled", true);
        }
      });
    } else {
      // Se tudo der errado mandar a notificação
      toastr.error("Erro no fluxo do veículo ativo", "Entregador");
    }
  }

  // Verifica o status das documentações
  needsDocuments() {
    // Obter todas os tipos de veículos presentes na tela
    const vehicleNames = this.needsDocumentsTargets.map((target) => {
      const vehicleSelectElement = target.querySelector(".vehicle-type");
      return vehicleSelectElement.options[
        vehicleSelectElement.options.selectedIndex
      ].textContent;
    });

    // Habilita o loading do modal
    document.querySelector(".modal div.overlay").classList.remove("d-none");

    // Monta as uqery params da url
    const queryParams = vehicleNames.map((e) => `q[name_in][]=${e}`).join("&");

    // realiza a requisição para obter as regras dos veículos
    fetch(`/administration/vehicle_types.json?${queryParams}`)
      .then((response) => response.json())
      .then((data) => {
        // prepara os parametros para ver quais campos não podem aparecer na tela
        let vehicleDocumentsValidation = false;
        let condumotoDocumentsValidation = false;
        let renavamDocumentsValidation = [];

        // Verifica a visibilidade dos campos conforme a regra dos veículos
        data.forEach((d) => {
          if (d.vehicle_documents_validation) vehicleDocumentsValidation = true;
          if (d.condumoto_documents_validation)
            condumotoDocumentsValidation = true;
          renavamDocumentsValidation.push({
            id: d.id,
            renavam_documents_validation: d.renavam_documents_validation,
          });
        });

        // Verifica a existẽncia do renavam para cada tipo de documento
        this.needsDocumentsTargets.map((target) => {
          const vehicleSelectElement = target.querySelector(".vehicle-type");
          const findVehicle = renavamDocumentsValidation.filter(
            (r) => r.id == vehicleSelectElement.value,
          );

          this.visibleDocumentContainer(
            findVehicle.length === 1 &&
              findVehicle[0].renavam_documents_validation,
            target.querySelector("#renavam"),
          );
        });

        // Verifica a existência da CNH
        this.visibleDocumentContainer(
          vehicleDocumentsValidation,
          document.querySelector(".modal div#driver-license"),
        );

        // Verifica a existência da condumoto
        this.visibleDocumentContainer(
          condumotoDocumentsValidation,
          document.querySelector(".modal div#condumoto"),
        );
      })
      .catch((err) =>
        toastr.error("Erro ao verificaçar as documentações", "Entregador"),
      ) // Quando der algum erro
      .finally(() =>
        document.querySelector(".modal div.overlay").classList.add("d-none"),
      ); // Elimina o loading do modal
  }

  // controle do select do employment_type do formulário de cadastro
  onRecordBackAccount() {
    if (this.employmentTypeFieldTarget.options.selectedIndex === 0) {
      this.franchiseIdentifierFieldTarget.classList.remove("d-none");
      this.bankAccountContainerTarget.classList.add("d-none");
    } else {
      this.franchiseIdentifierFieldTarget.classList.add("d-none");
      this.bankAccountContainerTarget.classList.remove("d-none");
    }
  }

  // Limitar a quantidade de contas bancárias que o entregador pode ter
  handleAddBankAccount() {
    const addBankAccount = document.querySelector("#add-bank-account");
    const bankAccountsChildren = document.querySelectorAll(".bank-account");

    if (bankAccountsChildren.length === 3) {
      addBankAccount.setAttribute("disabled", "true");
      toastr.info("O entregador deve ter até 3 contas bancárias");
    }
  }

  // Desabilitar a ação para adicionar novas contas
  handleRemoveBankAccount() {
    document.querySelector("#add-bank-account").removeAttribute("disabled");
  }

  // Muda o modo de operação do Entregador no aplicativo da levoo
  switchStatus(event) {
    const delivererId = event.params.id;

    document.querySelector(".loader-container").classList.remove("d-none");

    const url = `/administration/deliverers/${delivererId}/toggle_status`;
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: delivererId }),
    };

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        Swal.fire({
          text: data.message,
          icon: "success",
          willClose: () => {
            document
              .querySelector(".loader-container")
              .classList.remove("d-none");
            Turbolinks.visit("deliverers");
          },
        });
      })
      .catch((error) => {
        new Swal({ text: error.responseJSON.message, type: "error" });
      })
      .finally(() =>
        document.querySelector(".loader-container").classList.add("d-none"),
      );
  }

  // Muda o status de acesso do Entregador no aplicativo da levoo
  switchBlocked(event) {
    const delivererId = event.params.id;
    const url = `/administration/deliverers/${delivererId}/toggle_blocked`;
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    };

    if (event.params.toggleBlocked === "Bloquear") {
      Swal.fire({
        title: "Descreva o motivo do bloqueio",
        input: "text",
        inputPlaceholder: "Descreva aqui o motivo do bloqueio",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Bloquear",
        showLoaderOnConfirm: true,
        preConfirm: async (reason) => {
          (requestOptions.body = JSON.stringify({
            id: delivererId,
            reason: reason,
          })),
            await fetch(url, requestOptions)
              .then((resp) => resp.json())
              .then((data) => {
                new Swal({
                  text: "Entregador bloqueado com sucesso",
                  icon: "success",
                  willClose: () => {
                    document
                      .querySelector(".loader-container")
                      .classList.remove("d-none");
                    Turbolinks.visit(event.params.redirectPath);
                  },
                });
              })
              .catch((error) => {
                document
                  .querySelector("loader-container")
                  .classList.remove("d-none");
                new Swal({ text: error.responseJSON.message, icon: "error" });
              });
        },
      });
      return;
    }

    Swal.fire({
      title: "Entregador sendo desbloqueado",
      text: "por favor, aguarde...",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    (requestOptions.body = JSON.stringify({ id: delivererId })),
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
          new Swal({
            text: "Entregador desbloqueado com sucesso",
            icon: "success",
            willClose: () => {
              document
                .querySelector(".loader-container")
                .classList.remove("d-none");
              Turbolinks.visit(event.params.redirectPath);
            },
          });
        })
        .catch((error) => {
          new Swal({ text: error.responseJSON.message, icon: "error" });
        });
  }

  // Privados

  visibleDocumentContainer(conditional, element) {
    if (!conditional) {
      element.classList.add("d-none");
    } else {
      if (element.classList.contains("d-none"))
        element.classList.remove("d-none");
    }
  }
}
